
.hot-goods {
  box-sizing: border-box;
  height: 100%;
  padding: 10px 30px 30px 30px;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 20px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .two-line-text {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
